.Note {
    width: 300px;
    height: 400px;
    justify-self: center;
    background-color: aliceblue;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
    font-family: "Poppins", sans-serif;
}

.DarkMode .Note {
    background-color: rgb(34, 34, 34);
    color: rgb(205, 215, 224);
}

textarea {
    border: none;
    resize: none;
    text-overflow: ellipsis;
    font-family: inherit;
    font-size: inherit;
    background-color: aliceblue;
}

.DarkMode textarea {
    background-color: rgb(34, 34, 34);
    color: rgb(205, 215, 224);
}

textarea:focus {
    outline: none;
}

textarea:placeholder-shown {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
}

.DarkMode textarea::placeholder {
    color: rgb(205, 215, 224);
}

.Note .NoteHeader {
    height: 30px;
    background-color: rgba(107, 170, 244, 1);
    text-align: left;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DarkMode .Note .NoteHeader {
    background-color: rgb(65, 93, 126);
}

.Note .NoteHeader textarea {
    background-color: rgba(107, 170, 244, 1);
    font-size: 18.72px;
}

.DarkMode .Note .NoteHeader textarea {
    background-color: rgb(65, 93, 126);
}

.Note .NoteHeader h3 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: #000;
    opacity: 0.8;
}

.DarkMode .NoteHeader h3 {
    color: rgb(205, 215, 224);
    font-weight: 400;
}

.Icon {
    font-size: 20px;
    color: aliceblue;
    cursor: pointer;
}

.DarkMode .Icon {
    color: rgb(205, 215, 224);
}

.Note .NoteHeader .Icons .Icon {
    padding-left: 8px;
}

.Icon:active {
    transform: scale(0.95);
}

.Icon:hover {
    color: rgba(0, 0, 0, 0.5);
}

.Note .NoteBody {
    margin: 0 16px;
    max-height: 300px;
    text-align: justify;
    overflow: hidden;
}

.Note .NoteBody p {
    max-width: 300px;
    height: fit-content;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.Note .NoteBody textarea {
    margin: 16px 0 0 0;
    text-align: justify;
}

.Note .NoteFooter {
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Note .NoteFooter button {
    margin: 0;
}

.NewNoteBg {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
}

.NewNote {
    z-index: 2;
    position: relative;
    margin: 150px auto;
    overflow-x: hidden;
}

button .Close {
    cursor: pointer;
}
